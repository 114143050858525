import React, {useState, useEffect} from 'react';
import {UserDetailsContext} from './UserDetailsContext';
import {useUser} from 'src/api/Auth/Auth';

// Créer le fournisseur de contexte
export const UserDetailsProvider = ({children}) => {
  const {user, isLoading, isError, mutate} = useUser();
  const [userDetails, setUserDetails] = useState(null);
  const [isStripeConnected, setIsStripeConnected] = useState(false);

  // Fonction pour rafraîchir les détails utilisateur sans recharger la page
  const refreshUserDetails = async () => {
    const updatedUser = await mutate(); // Rechargement des informations utilisateur via `mutate`
    if (updatedUser && !isError) {
      const onboardingCompleted = updatedUser.companyDetails.length > 0 ?
        updatedUser.companyDetails[0].onboardingCompleted :
        false;
      const stripeConnected = !!updatedUser.companyDetails[0]?.stripeAccountId;

      const updatedUserDetails = {
        ...updatedUser,
        onboardingCompleted,
      };

      setUserDetails(updatedUserDetails);
      setIsStripeConnected(stripeConnected);
    }
  };

  // Initialisation des détails utilisateur et de l'état de connexion Stripe
  useEffect(() => {
    if (user && !isError) {
      const onboardingCompleted = user.companyDetails.length > 0 ?
        user.companyDetails[0].onboardingCompleted :
        false;
      const stripeConnected = !!user.companyDetails[0]?.stripeAccountId;

      const initialUserDetails = {
        ...user,
        onboardingCompleted,
      };

      setUserDetails(initialUserDetails);
      setIsStripeConnected(stripeConnected);
    }
  }, [user, isError]);

  // Valeurs fournies par le contexte
  const value = {
    userDetails,
    isStripeConnected, // Ajout de l'état de connexion Stripe
    isLoading,
    isError,
    refreshUserDetails, // Fonction pour recharger dynamiquement les détails utilisateur
  };

  return (
    <UserDetailsContext.Provider value={value}>
      {children}
    </UserDetailsContext.Provider>
  );
};
