// @react
import React from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import {Avatar, Box, Divider, Stack, Typography} from '@mui/material';
// hooks
import {useUserDetailsContext} from 'src/hooks';
// components
import Label from 'src/components/label';
import {Link} from 'react-router-dom';
import {paths} from 'src/routes/paths';
import SalesPageLink from './sales-page-link';

// ----------------------------------------------------------------------
/**
 * `NavUpgrade` component displays a section for the navigation sidebar.
 * It showcases the current user's avatar, name, email, and their current subscription status.
 * There's also a button prompting the user to upgrade to the Pro version.
 *
 * @return {JSX.Element} The rendered component.
 */
export default function NavUpgrade() {
  const theme = useTheme();
  const {userDetails} = useUserDetailsContext();

  // Accès à l'état d'onboarding du premier établissement
  const isOnboardingCompleted = userDetails?.companyDetails?.[0]?.onboardingCompleted;

  // Définir les couleurs des rôles
  const roleColor = userDetails?.role === 'CompanyAdmin' ?
    theme.palette.primary.light :
    userDetails?.role === 'CompanyUser' ?
    theme.palette.secondary.light :
    theme.palette.grey[500];

  if (!isOnboardingCompleted) {
    return null;
  }

  return (
    <Stack sx={{px: 2, py: 5, textAlign: 'center'}}>
      <Stack alignItems="center">
        <Box sx={{position: 'relative'}}>
          <Avatar src={userDetails?.photoURL} alt={userDetails?.firstName} sx={{width: 48, height: 48}} />
          {userDetails?.role === 'CompanyAdmin' && (
            <Label
              color="primary"
              variant="filled"
              sx={{
                top: -6,
                px: 0.5,
                left: 40,
                height: 20,
                position: 'absolute',
                borderBottomLeftRadius: 2,
                backgroundColor: roleColor,
              }}
            >
              Admin
            </Label>
          )}
        </Box>
        <Stack spacing={0.5} sx={{mt: 1.5, mb: 2}}>
          <Link to={paths.app.userAccount} style={{textDecoration: 'none', color: 'inherit'}}>
            <Typography variant="subtitle2" noWrap>
              {userDetails?.firstName}
            </Typography>

            <Typography variant="body2" noWrap sx={{color: 'text.disabled'}}>
              {userDetails?.email}
            </Typography>
          </Link>
        </Stack>
      </Stack>
      <Divider sx={{my: 2}} />
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        <SalesPageLink />
      </Stack>
    </Stack>
  );
}
