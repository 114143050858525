import {useCallback} from 'react';
import axiosInstance, {endpoints} from 'src/utils/axios';
import useSWR from 'swr';
import {useSnackbar} from 'src/components/snackbar/';
import {useRouter} from 'src/routes/hooks';
import {paths} from 'src/routes/paths';
import {useAuthContext} from 'src/hooks/useAuthContext';

// Hook pour l'inscription des utilisateurs
export const useRegister = () => {
  const {enqueueSnackbar} = useSnackbar();

  const register = async (email, password, companyName, firstName, lastName) => {
    try {
      const response = await axiosInstance.post(endpoints.auth.register, {
        email, password, companyName, firstName, lastName,
      });
      enqueueSnackbar('Inscription réussie !', {variant: 'success'});
      return response.data;
    } catch (error) {
      console.error('Erreur lors de l’enregistrement:', error);

      let errorMessage = 'Erreur inconnue du serveur'; // Message par défaut
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      enqueueSnackbar(errorMessage, {variant: 'error'});
      throw error;
    }
  };

  return register;
};

// Hook pour la connexion des utilisateurs
export const useLogin = () => {
  const {mutate} = useUser();
  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post(endpoints.auth.login, {email, password});
      mutate();
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la connexion:', error.response || error); // Affiche plus de détails sur l'erreur
      throw error;
    }
  };

  return login;
};

// Hook pour la déconnexion des utilisateurs
export const useLogout = () => {
  const {enqueueSnackbar} = useSnackbar();
  const router = useRouter();
  const {logout} = useAuthContext();
  const {mutate} = useUser();

  const logoutAction = async () => {
    try {
      await axiosInstance.post(endpoints.auth.logout);
      logout();
      mutate(null, false);
      enqueueSnackbar('Déconnexion réussie !', {variant: 'success'});
      router.push(paths.auth.login); // Redirection vers la page de connexion
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
      const errorMessage = error?.response?.data?.message || 'Erreur inconnue du serveur';
      enqueueSnackbar(errorMessage, {variant: 'error'});
      throw error;
    }
  };

  return logoutAction; // Retourne la nouvelle fonction de déconnexion ajustée
};

// Hook pour récupérer les informations de l'utilisateur connecté
export const useUser = () => {
  const {isAuthenticated} = useAuthContext(); // Utilisation de useAuthContext pour vérifier l'authentification

  // Conditionne l'exécution de fetchUser à l'état d'authentification
  const fetchUser = isAuthenticated ?
  (url) => axiosInstance.get(url).then((res) => {
    return res.data;
  }) :
  null;
  // useSWR n'effectuera l'appel API que si isAuthenticated est vrai et fetchUser est défini
  const {data, error, mutate} = useSWR(
    isAuthenticated ? endpoints.auth.user : null, // Conditionne l'URL à l'état d'authentification
    fetchUser,
  );

  const isLoading = !error && !data;
  if (error) console.error('Erreur lors de la récupération des données utilisateur:', error);

  return {
    user: data,
    isLoading,
    isError: !!error,
    mutate,
  };
};

// Hook pour vérifier la validité du token
export const useVerifyToken = () => {
  const {enqueueSnackbar} = useSnackbar();
  const router = useRouter();
  const {setAuthInfo} = useAuthContext();

  const verifyToken = useCallback(async () => {
    try {
      const response = await axiosInstance.get(endpoints.auth.verifyToken);

      if (response && response.data) {
        // Si la requête réussit, le token est valide et nous mettons à jour l'état d'authentification
        setAuthInfo({
          userId: response.data.userId,
          role: response.data.role,
          emailVerified: response.data.emailVerified,
          isOnboardingCompleted: response.data.isOnboardingCompleted,
          isAuthenticated: true,
          loading: false,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la vérification du token:', error);
      const errorMessage = error?.response?.data?.message || 'Erreur inconnue du serveur';
      enqueueSnackbar(errorMessage, {variant: 'error'});

      // Nettoyage direct du localStorage
      localStorage.removeItem('authState');

      // Mise à jour de l'état d'authentification pour réinitialiser
      setAuthInfo({
        isAuthenticated: false,
        userRole: null,
        userId: null,
        emailVerified: false,
        isOnboardingCompleted: false,
        loading: false,
      });

      // Redirection vers la page de connexion
      router.push(paths.auth.login);
    }
  }, [enqueueSnackbar, router, setAuthInfo]);

  return verifyToken;
};

// Hook pour demander la réinitialisation du mot de passe
export const useRequestResetPassword = () => {
  const {enqueueSnackbar} = useSnackbar();

  const requestResetPassword = async (email) => {
    try {
      const response = await axiosInstance.post(endpoints.auth.requestResetPassword, {email});
      enqueueSnackbar(response.data.message, {variant: 'success'});
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la demande de réinitialisation du mot de passe:', error);

      const errorMessage = error?.response?.data?.message || 'Erreur inconnue du serveur';
      enqueueSnackbar(errorMessage, {variant: 'error'});
      throw error;
    }
  };

  return requestResetPassword;
};

// Hook pour réinitialiser le mot de passe
export const useResetPassword = () => {
  const {enqueueSnackbar} = useSnackbar();

  const resetPassword = async (token, newPassword) => {
    try {
      const response = await axiosInstance.post(endpoints.auth.resetPassword, {token, newPassword});
      enqueueSnackbar('Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter.', {variant: 'success'});
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la réinitialisation du mot de passe:', error);

      const errorMessage = error?.response?.data?.message || 'Erreur inconnue du serveur';
      enqueueSnackbar(errorMessage, {variant: 'error'});
      throw error;
    }
  };

  return resetPassword;
};

export const useVerifyResetToken = (token) => {
  const {enqueueSnackbar} = useSnackbar();

  const verifyResetToken = async () => {
    try {
      await axiosInstance.get(endpoints.auth.verifyResetToken(token));
      return true;
    } catch (error) {
      console.error('Erreur lors de la vérification du token de réinitialisation:', error);
      const errorMessage = error?.response?.data?.message || 'Erreur inconnue du serveur';
      enqueueSnackbar(errorMessage, {variant: 'error'});
      return false;
    }
  };

  return verifyResetToken;
};

