import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/guards';
import {RoleBasedGuard} from 'src/guards';
// layouts
import DashboardLayout from 'src/layouts/admin/layout';
// components
import {LoadingScreen} from 'src/components/loading-screen';
// roles
import {paths} from 'src/routes/paths';
import {routeRoles} from 'src/utils/rolesConfig';
// context
import {AuthProvider} from 'src/contexts/auth-context';
import {UserDetailsProvider} from 'src/contexts/user-details-context';

// ----------------------------------------------------------------------

const AdminPageDashboard = lazy(() => import('src/pages/admin/dashboard/view/DashboardPage'));
const AdminPageUsers = lazy(() => import('src/pages/admin/users/view/UsersListPage'));
const AdminPageGiftVoucherType = lazy(() => import('src/pages/admin/gift-voucher-type/view/GiftVoucherTypePage'));
const AdminPageGiftVoucher = lazy(() => import('src/pages/admin/gift-voucher/view/GiftVouchersListPage'));

// ----------------------------------------------------------------------

export const adminRoutes = [
  {
    path: '/admin',
    element: (
      <AuthProvider>
        <UserDetailsProvider>
          <AuthGuard>
            <RoleBasedGuard roles={routeRoles[paths.admin]}>
              <DashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </DashboardLayout>
            </RoleBasedGuard>
          </AuthGuard>
        </UserDetailsProvider>
      </AuthProvider>
    ),
    children: [
      {element: <AdminPageDashboard />, index: true},
      {path: 'dashboard', element: <AdminPageDashboard />},
      {path: 'users', element: <AdminPageUsers />},
      {path: 'catalog', element: <AdminPageGiftVoucherType />},
      {path: 'sales', element: <AdminPageGiftVoucher />},
    ],
  },
];
